import * as React from "react";
import { PropertyPolicies as SharedUiPropertyPolicies } from "@shared-ui/lodging-property-details";
import { PropertyBexApiWrapper } from "components/shared/BexApiWrapper/PropertyBexApiWrapper";
import { RefTarget } from "@shared-ui/ref-context";
import { UitkSpacing } from "@egds/react-core/spacing";
import { PropertyPoliciesProps } from "./types";
import { useExperiment } from "@shared-ui/experiment-context";

const WrappedPropertyPolicies = PropertyBexApiWrapper(SharedUiPropertyPolicies);

export const PropertyPolicies = (props: PropertyPoliciesProps) => {
  const { context, templateComponent } = props;
  const { displayThreshold, enableExpando, showBorder } = templateComponent.config;
  const { exposure } = useExperiment("Dateless_PDP_Progressive_disclosure_Fully_collapsed_content_sections");
  const disableSectionSeparator = exposure.bucket === 1;

  const getSpacing = () => {
    if (showBorder && displayThreshold === 0) {
      return {
        small: { blockend: "two", inline: "three" },
        medium: { blockend: "two", inline: "unset" },
      };
    }
    if (showBorder) {
      return {
        small: { blockend: "six", inline: "three" },
        medium: { blockend: "six", inline: "unset" },
      };
    }
    if (disableSectionSeparator) {
      return "unset";
    }
    return { block: "three" };
  };

  return (
    <UitkSpacing margin={getSpacing()}>
      <RefTarget name="Policies" tag="section">
        <WrappedPropertyPolicies
          context={context}
          displayThreshold={displayThreshold}
          isExpandoSection={enableExpando}
          disableSectionSeparator={disableSectionSeparator}
          showBorder={showBorder}
        />
      </RefTarget>
    </UitkSpacing>
  );
};

export default PropertyPolicies;
